// src/Section1.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Section1.css'; 

const Section1 = () => {
  return (
    <Container className="section1 my-5" id='overview'>
      <Row className="align-items-center">
        <Col lg={6} md={12} className="text-section">
          <h1 className='bort-title vibration'>SUITRUMP</h1>
          <p>It's time to make SUI great again.</p>
        </Col>
        <Col lg={6} md={12} className="image-section text-center">
          <img src="logo.svg" alt="Decorative" className="img-fluid vibration" />
        </Col>
      </Row>
    </Container>
  );
};

export default Section1;
