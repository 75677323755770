// src/Section4.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Section3.css'; 

const Section3 = () => {
  return (
    <Container className="section4 text-center" id='joinus'>
      <Row>
        <Col>
        <h1 className="section4-title">Join us</h1>
          <p className="section4-text">
          It's time to make Sui great again.
        </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Section3;
