// src/Section2.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Section2.css';
import image1 from './logo.svg'; 



const Section2 = () => {
  return (
    <Container className="section2 my-5" id='tokenomics'>
      <Row className="text-center mb-4">
        <Col>
          <h1>Tokenomics</h1>
          <br></br>
          <h3>$SUITRUMP will be launched on movepump.com on Sui network.</h3>
          <br></br>
          <h4>Contract: </h4>
        </Col>
      </Row>
      <Row className="g-4 justify-content-center">
      <Col lg={4} md={6} sm={12}>
        <Card className="chapter-card">
            <Card.Body>
            <Card.Title>Supply</Card.Title>
            <img src={image1} alt="img1" className="card-image mx-auto d-block" />
            <Card.Text>
                1,000,000,000 $SUITRUMP
            </Card.Text>
            </Card.Body>
        </Card>
       </Col>


       <Col lg={4} md={6} sm={12}>
        <Card className="chapter-card">
            <Card.Body>
            <Card.Title>Tax structure</Card.Title>
            <img src={image1} alt="img2" className="card-image mx-auto d-block" />
            <Card.Text>
            No taxes
            </Card.Text>
            </Card.Body>
        </Card>
       </Col>


       <Col lg={4} md={6} sm={12}>
        <Card className="chapter-card">
            <Card.Body>
            <Card.Title>Mint</Card.Title>
            <img src={image1} alt="img3" className="card-image mx-auto d-block" />
            <Card.Text>
            Revoked
            </Card.Text>
            </Card.Body>
        </Card>
       </Col>


       <Col>

          <br></br>
        </Col>


      </Row>
    </Container>
  );
};

export default Section2;
